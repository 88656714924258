import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import AWSBillingData from './components/AWSBillingData';
import AWSEmailData from './components/AWSEmailData';

function DashboardPage(props) {

  const [awsBillingData, setAWSBillingData] = useState([]);
  const [awsSesData, setAWSSesData] = useState([]);
  const [propertyDataRateLimitData, setPropertyDataRateLimitData] = useState([]);

  const [dashboardLoaded, setDashboardLoaded] = useState(false);

  const fetchDashboardData = () => {
    var fUrl = process.env.REACT_APP_API_END_POINT + 'admindashboard/';
      
      fetch(fUrl,
        {
          headers: {
            'Accept': 'application/json',
          },
          method: "POST",
          credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data['success']) {
            setAWSBillingData(data['billing_data']);
            setAWSSesData({
              quota: data['ses_data']['Max24HourSend'],
              sent: data['ses_data']['SentLast24Hours'],
              stats: data['ses_data']['SendStatistics']
            })
            setPropertyDataRateLimitData(data['propertyDataRateLimitData']);
            setDashboardLoaded(true);
          } else {
            setAWSBillingData([])
            setDashboardLoaded(false);
          }
        }
      );
  }

  // const awsBillDateList = billingData[billingData.keys[0]];

  // on component mount, fetch the data for the dashboard
  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPropertyDataTable = (data) => {
    var gridTemplateColumns = "120px";
    data.forEach(() => {
      gridTemplateColumns = gridTemplateColumns + " 100px";
    })
    return (
      <div style={{display: "grid", gridTemplateColumns: gridTemplateColumns, rowGap: "1px", columnGap: "1px"}}>
        <div style={{backgroundColor: "#9898FF"}}></div>
        {
          data.map(d => {
            return <div style={{fontWeight: "bold", backgroundColor: "#9898FF"}}>{d['date']}</div>
          })
        }
        <div style={{fontWeight: "bold"}}>Over limits</div>
        {
          data.map(d => {
            return (
              <div>{ d['count'] === 0 ? '-' : d['count'] }</div>
            )
          })
        }
      </div>
    )
  }

    return (
      <div className="container">
        {
          (!dashboardLoaded) ?
          <div className="my-5" style={{ textAlign: "center" }}>
            <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
          </div> :
          <>
            <AWSBillingData awsBillingData={awsBillingData} />
            {(('quota' in awsSesData) && ('sent' in awsSesData) && ('stats' in awsSesData)) ?
              <AWSEmailData awsSesData={awsSesData} /> : null 
            }
            <br/>
            <h1 class="adminDashboard">PropertyData API rate limit exceedances</h1>
            <p>Data for last 2 weeks</p>
            { getPropertyDataTable(propertyDataRateLimitData) }
          </>
        }
      </div>
    );
}

export default DashboardPage;