import React, { useEffect, useState } from 'react';
import ShareReportButton from './ShareReportButton';
import EmailReportButton from './EmailReportButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import RegenerateReportButton from './RegenerateReportButton';

function ReportsListItem(props) {

    const [pillClass, setPillClass] = useState("text-bg-info");
    const [hoverRow, setHoverRow] = useState(null);

    const onClick = () => {
        if (props.report.status === "Ready!") {
            const url = process.env.REACT_APP_API_END_POINT + 'adminreporturl';
            fetch(url, {
              headers: {
                'Accept': 'application/json',
              },
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                "report_id": props.report.report_id,
                "report_mode": props.reportMode,
                "username": props.report.user
              })
            })
            .then((resp) => resp.json())
            .then((data) => {
              if (data['success'] === true) {
                const url = data['report_url'];
                window.open(url, '_blank', 'noopener,noreferrer');        
              }
            });
        }
    }

    useEffect(() => {
        if (props.report.status === "Ready!") {
            setPillClass("text-bg-primary");
        } else if (props.report.status === "Error") {
            setPillClass("text-bg-danger");
        } else if (props.report.status === "Regenerating") {
            setPillClass("text-bg-warning");
        } else {
            setPillClass("text-bg-info");
        }
    }, [props.report.status]);

    const date = new Date(props.report.created_at * 1000);

    var epcRating = "";
    var epcExtraClasses = "dataCell";
    if (props.report.current_epc_band !== null && props.report.current_epc !== null) { // these can be null with some very old objects, probably won't need this after go live
        if (props.report.current_epc_type === 'ACTUAL') {
            epcRating = props.report.current_epc_band + " (" + props.report.current_epc + ")";
            epcExtraClasses = "dataCellEpcRating" + props.report.current_epc_band
        } else if (props.report.current_epc_type === 'ESTIMATE') {
            epcRating = "~" + props.report.current_epc_band + " (" + props.report.current_epc + ")";
            epcExtraClasses = "dataCellEpcRating" + props.report.current_epc_band
        } else {
            epcRating = "???";
            epcExtraClasses = "dataCellEpcRatingUnknown"
        }
        
    }

    const createCellDiv = (key_suffix, style, contents, isEpc = false) => {

        var classes = '';
        if (!isEpc) {
            classes = `dataGridCell ${props.report.report_id === hoverRow ? 'dataGridCellHover' : ''}`
        } else {
            classes = "dataCell dataCellEpc " + epcExtraClasses;
        }

        return (
            <div
                onMouseEnter={() => setHoverRow(props.report.report_id)}
                onMouseLeave={() => setHoverRow(null)}
                onClick={onClick}
                key={props.report.report_id + ' ' + key_suffix}
                className={classes}
                style={style}
            >
                {contents}
            </div>
        )
    }

    return (
        <>
            {/* Date, always */}
            {createCellDiv('date', {textAlign: "left"}, date.toLocaleDateString())}

            {/* The assessor username, premium - eg, who created the original free report */} 
            {props.reportMode === 'premium' ? createCellDiv('assessor', {textAlign: "left"}, <strong>{props.report.preliminary_report_username}</strong>) : null }

            {/* User, always */}
            {createCellDiv('user', {textAlign: "left"}, <strong>{ props.report.user }</strong>)}

            {/* Surveyor, only for XML reports */}
            {props.reportMode === 'free' ? createCellDiv('surveyor', {textAlign: "left"}, props.report.assessor_ref ) : null }

            {/* Name, always */}
            {createCellDiv('name', {textAlign: "left"}, props.report.name)}

            {/* EPC, always */}
            {createCellDiv('epc', {textAlign: "center"}, epcRating, true)}
            
            {/* Flag for free XML upgraded to premium, only for free XML */}
            {props.reportMode === 'free' ? createCellDiv('premium', {textAlign: "center"}, props.report.premium_report_purchased ? <FontAwesomeIcon icon={solid("check")} style={{color: "#000000"}} /> : <FontAwesomeIcon icon={solid("xmark")} style={{color: "#000000"}} />) : null }

            {/* Deletion status, only for free XML uploads */}
            {props.reportMode === 'free' ? createCellDiv('status', {textAlign: "center"}, props.report.deletion_status) : null}
            
            {/* "Emailed", this is about if a premium XML report has been emailed to its owner yet, only for premium XML */}
            {props.reportMode === 'premium' ? 
                createCellDiv('emailed', {textAlign: "center"}, 
                    props.report.email_count === 0 ?
                        <FontAwesomeIcon icon={solid("xmark")} style={{color: "#000000"}} />
                        :
                        <>
                            <FontAwesomeIcon icon={solid("check")} style={{color: "#000000"}} />
                            { props.report.email_count > 1 ? <> x{props.report.email_count}</> : null }
                        </>
                )
            : null }

            {/* Actions, always */}
            {createCellDiv('actions', {textAlign: "right"}, <>
                <span className={'badge rounded-pill ' + pillClass}>{ props.report.status }</span>
                <RegenerateReportButton report={ props.report } reportMode={ props.reportMode } />
                { props.report.status === 'Ready!' ? <ShareReportButton report={ props.report } /> : null }
                { props.report.status === 'Ready!' && props.reportMode === 'premium' ? <EmailReportButton report={ props.report } /> : null }
            </>)}
        </>
    );
}

export default ReportsListItem;