import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


function EmailReportButton(props) {

  const [show, setShow] = useState(false);

  const [emailAddress, setEmailAddress] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");

  const [sendInProgress, setSendInProgress] = useState(false);

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = (event) => {
    setEmailAddress(props.report.user);
    setEmailSubject("Your epIMS Premium Report for " + props.report.short_title + " is ready");
    setEmailBody("Dear " + props.report.shipping_name + ",\n\nThank you for purchasing an epIMS Premium Report for your property at " + props.report.short_title + ".\n\nYour epIMS Premium Report is now ready and you can view it at: (LINK)\n\nIf you are having any difficulties viewing your report, please email us at info@epims.co.uk.\n\nKind regards,\n\nThe epIMS Team.");
    event.stopPropagation();
    setShow(true);
  }

  const sendEmail = (event) => {
    setSendInProgress(true);
    const url = process.env.REACT_APP_API_END_POINT + 'reportcompleteemail';
    fetch(url,
      {
        headers: {
          'Accept': 'application/json',
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          email_address: emailAddress,
          email_subject: emailSubject,
          email_body: emailBody,
          report_id: props.report.report_id,
          report_user: props.report.user,
        })
      })
      .then((resp) => resp.json())
      .then((data) => {
        // should check the resonse?
        setShow(false);
        setSendInProgress(false);
      });
  }

  return (
    <>
      <Button onClick={handleShow} style={{"marginLeft": "5px"}} className="btn btn-primary btn-sm">
        <FontAwesomeIcon icon={solid("envelope")} style={{color: "#FFFFFF"}} />
      </Button>
      <div style={{display: "none"}} onClick={(e) => e.stopPropagation()}>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Email report to customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Send email to customer with link to the report. Use &quot;(LINK)&quot; to insert the link.</p>
            <p><strong>To:</strong> <input size="40" type="text" name="emailAddress" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} /></p>
            <p><strong>Subject: </strong> <input size="70" type="text" name="emailSubject" value={emailSubject} onChange={e => setEmailSubject(e.target.value)} /></p>
            <p className="mb-1"><strong>Body:-</strong></p>
            <textarea className="mx-2" cols="78" rows="12" value={emailBody} onChange={e => setEmailBody(e.target.value)} />
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={sendInProgress} variant="primary" onClick={sendEmail}>
              {
                sendInProgress ?
                  <><FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000"}} />&nbsp;Sending</>
                :
                  <>Send</>
              }
            </Button>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default EmailReportButton;
