function AWSBillingData(props) {

  
  const services = Object.keys(props.awsBillingData);
  const billingDates = Object.keys(props.awsBillingData[services[0]]);

  const displayPrice = (price) => {
    if (price === 0) {
        return "-";
    }
    const USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });  
    const formatted = USDollar.format(price)
    if (formatted === "$0.00") {
        return "< $0.01";
    }
    return formatted;
  }

  return (
    <div>
        <h1 className="adminDashboard">AWS service costs, updates ~daily</h1>
        <div className="row m-0 p-0">
          <div className="col-3 mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>Service</strong></div> 
          { billingDates.map((billingDate) => { return <div key={billingDate} className="col-1 mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>{ billingDate }</strong></div> }) } 
        </div>
        
            { services.map((service) => { return (
                
                    <div className="row m-0 p-0">
                        <div className="col-3 mx-0 dataCell" style={{textAlign: "left"}}>{service}</div>
                        { billingDates.map((billingDate) => { return (
                            <div className="col-1 mx-0 dataCell" style={{textAlign: "right"}}>{ 
                                displayPrice(props.awsBillingData[service][billingDate])
                            }</div>
                        )})}
                    </div>
                
            ) }) }
        <br/>        
    </div>
  );

}

export default AWSBillingData;
