import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function RegenerateReportButton(props) {

    const [requestInProgress, setRequestInProgress] = useState(false);

    const regenerateReport = (e) => {
        e.stopPropagation();
        setRequestInProgress(true);
        setTimeout(() => {setRequestInProgress(false)}, 7000);
        console.log("Will regenerate " + props.report.user + "#" + props.report.report_id);

        const url = process.env.REACT_APP_API_END_POINT + 'regeneratereport';
        
        fetch(url,
          {
            headers: {
              'Accept': 'application/json',
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
              "report_id": props.report.report_id,
              "user": props.report.user,
              "report_mode": props.reportMode,
            })
          })
          .then((resp) => resp.json())
          .then((data) => {
            // should check the response?
          });
    }

    if (props.report.status === 'Ready!' || props.report.status === 'Error') {
        return (
            <Button onClick={ e => regenerateReport(e) } style={{marginLeft: "5px"}} className="btn btn-sm" disabled={requestInProgress}>
                { requestInProgress ?
                  <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#FFFFFF"}} /> :
                  <FontAwesomeIcon icon={solid("arrows-spin")} style={{color: "#FFFFFF"}} />
                }
            </Button>
        );
    }
}

export default RegenerateReportButton;