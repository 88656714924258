import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ReorderablePlan from "./components/ReorderablePlan";
import { Button } from "react-bootstrap";

var masterData = {}; // I'm told this might not be a good idea, but it doesn't matter that it isn't in state (its not rendered, just updated for later saving by other events), and does not want to be reset with each render. Also stores an object so updating it by state control is tricky (keys get updated in rapid succession before previous change has persisted)

function ProcessReportPage(props) {

  const routeParams = useParams();
  const navigate = useNavigate();

  const [reportData, setReportData] = useState(null);

  const [plans, setPlans] = useState({});
  const [planIds, setPlanIds] = useState([]);

  const [epcStart, setEpcStart] = useState(0);
  const [eirStart, setEirStart] = useState(0);

  const [shippingName, setShippingName] = useState("");
  const [priority, setPriority] = useState("");
  const [premiumReportUsername, setPremiumReportUsername] = useState("");

  const submitPremiumReportData = () => {
    const url = process.env.REACT_APP_API_END_POINT + 'finalisepremiumreport/';
    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        "report_id": reportData['report_id'],
        "plan_data": masterData,
        "mode": props.mode,
      })
    })
    .then((resp) => resp.json())
    .then((data) => {
      if (data['success'] === false) {
        alert("Something went wrong, try again or speak to Tim. " + data['error']);
      } else {
        navigate('/incompleteReports');
      }
    });
  }

  const updateMasterData = (planId, data, key) => {
    var _masterData = {
      ...masterData,
      [planId]: {...masterData[planId], [key]: data}
    }
    masterData = _masterData;
  }

  useEffect(() => {

    const url = process.env.REACT_APP_API_END_POINT + 'unprocessedpremiumreport/';

    let requestParameters;
    if (props.mode === 'premium') {
      requestParameters = {
        "report_id": routeParams.reportId,
        'mode': 'premium',
      }
    } else {
      requestParameters = {
        'report_id': routeParams.appointmentUploadSetId,
        'mode': 'appointmentPremium',
      }
    }

    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify(
        requestParameters
      )
    })
    .then((resp) => resp.json())
    .then((data) => {
      if (data['success'] === false) {
        setReportData(false);
      } else {
        setReportData(data['report_data']);
        var plans = {}; // object, will key it by plan ID and store plans in it
        var planIds = []; // array, will keep a separate list of plan IDs, it will be a bit easier to loop over it and get all the plans from 'plans', rather than doing plans.entries... etc (as not possible to .map() an object)
        data['report_data']['plans'].forEach(plan => {
          plans[plan.id] = plan;
          planIds.push(plan.id);
        });
        setPlans(plans);
        setPlanIds(planIds);
        setEpcStart(data['report_data']['epcStart']);
        setEirStart(data['report_data']['eirStart']);
        setPriority(data['priority'])
        setShippingName(data['shipping_name']);
        setPremiumReportUsername(data['premium_report_username']);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reportData === null) {
    return ( <div className="my-5" style={{ textAlign: "center" }}>
              <div className="my-5">Loading</div>
              <div><FontAwesomeIcon className="spinner-slow" icon={solid("spinner")} style={{color: "#000000", height: "150px", width: "150px" }} /></div>
            </div>
          )
  } else if (reportData === false) {
    return ( <div>Error loading data</div> )
  } else {
    // probably should move this to a full component
    return ( 
      <div>
        <div className="pageSection">Downloads</div>
        <div>
          <div><strong>Report name:</strong> {reportData.name}</div>
          <div><strong>Downloads:-</strong></div>
          <div>
            <span style={{marginRight: "25px"}}>{ reportData.pdf_url === null ? <>No Preliminary PDF</> : <a href={reportData.pdf_url}>Preliminary PDF</a> }</span>
            <a style={{marginRight: "25px"}} href={reportData.lig_xml_url}>LIG XML</a>
            <span style={{marginRight: "25px"}}>{ reportData.rdsap_xml_url === null ? <>No RdSAP</> : <a href={reportData.rdsap_xml_url}>RdSAP XML</a> }</span>
          </div>
          <div><strong>Report for:</strong> {shippingName} - {premiumReportUsername} </div>
          <div><strong>Chosen priority:</strong> {priority}</div>
        </div>
        <div className="pageSection">Measures in XML</div>
        <div style={{width: "80%"}}>
          <div className="row m-0 p-0">
            <div className="col mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>Measure</strong></div>
            <div className="col-1 mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>EPC Points</strong></div>
            <div className="col-1 mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>EIR Points</strong></div>
            <div className="col-2 mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>Supplied cost</strong></div>
            <div className="col-2 mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>Calculated cost</strong></div>
            <div className="col-1 mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>Saving</strong></div>
            <div className="col-2 mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>ROI time</strong></div>
          </div>
          {
            reportData.measures.map((measure) => {
              return (
                <li key={"xmlImprovementsLi" + measure.code} className="p-0 m-0 list-group-item list-group-item-primary list-group-item-action" style={{border: "0px"}}>
                  <div className="row m-0 p-0">
                    <div className="col mx-0 dataCell" style={{textAlign: "left"}}><strong>({measure.code})</strong> {measure.name}</div>
                    <div className="col-1 mx-0 dataCell" style={{textAlign: "left"}}>{measure.epcIncrease}</div>
                    <div className="col-1 mx-0 dataCell" style={{textAlign: "left"}}>{measure.eirIncrease}</div>
                    <div className="col-2 mx-0 dataCell" style={{textAlign: "left"}}>{measure.implementationCostSupplied}</div>
                    <div className="col-2 mx-0 dataCell" style={{textAlign: "left"}}>&pound;{measure.implementationCost.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) }</div>
                    <div className="col-1 mx-0 dataCell" style={{textAlign: "left"}}>&pound;{measure.typicalSaving.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) }</div>
                    <div className="col-2 mx-0 dataCell" style={{textAlign: "left"}}>{measure.ROIYearsText}</div>
                  </div>
                </li>
              )
            })
          }
        </div>
        <div>
          <div className="pageSection">Plans</div>
          { planIds.map((planId) => {
              const plan = plans[planId];
              return <ReorderablePlan key={"planContainer" + planId} updateMasterData={(data, key) => updateMasterData(planId, data, key)} {...{planId, plan, epcStart, eirStart}} />
          }) }
        </div>
        <Button onClick={submitPremiumReportData}>Generate Premium Report</Button>
      </div>
     )
  }
}

export default ProcessReportPage;