import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { subscribe, unsubscribe } from './events';
import { useNavigate } from 'react-router-dom';

function IncompleteAppointmentsPage() {

  const [appointments, setAppointments] = useState([]);
  const [appointmentsLoaded, setAppointmentsLoaded] = useState(false);

  const [hoverRow, setHoverRow] = useState(null);

  const navigate = useNavigate();

  const fetchAppointmentsList = () => {
    var fUrl = process.env.REACT_APP_API_END_POINT + 'acuitylist/';
      
    fetch(fUrl,
      {
        headers: {
          'Accept': 'application/json',
        },
        method: "GET",
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data['success']) {
          setAppointments(data['appointments_list']);
          setAppointmentsLoaded(true);
        } else {
          setAppointments([]);
          setAppointmentsLoaded(false);
        }
      }
    );
  }

  const completeAppointment = (acuity_id) => {
    navigate('/appointments/' + acuity_id + '/upload');
  }

  const getAppointmentsListItem = (appointment) => {
    return (
      <>
        <div
          onMouseEnter={() => setHoverRow(appointment.acuity_id)}
          onMouseLeave={() => setHoverRow(null)}
          onClick={() => completeAppointment(appointment.acuity_id)}
          key={appointment.acuity_id + ' datetime'}
          className={`dataGridCell ${appointment.acuity_id === hoverRow ? 'dataGridCellHover' : ''}`}
          style={{textAlign: "left"}}
        >
          { appointment.is_past ? <FontAwesomeIcon icon={solid("triangle-exclamation")} beat /> : null } { appointment.date_time }
        </div>

        <div
          onMouseEnter={() => setHoverRow(appointment.acuity_id)}
          onMouseLeave={() => setHoverRow(null)}
          onClick={() => completeAppointment(appointment.acuity_id)}
          key={appointment.acuity_id + ' price'}
          className={`dataGridCell ${appointment.acuity_id === hoverRow ? 'dataGridCellHover' : ''}`}
          style={{textAlign: "left"}}
        >
          &pound;{ appointment.price }
        </div>

        <div
          onMouseEnter={() => setHoverRow(appointment.acuity_id)}
          onMouseLeave={() => setHoverRow(null)}
          onClick={() => completeAppointment(appointment.acuity_id)}
          key={appointment.acuity_id + ' name'}
          className={`dataGridCell ${appointment.acuity_id === hoverRow ? 'dataGridCellHover' : ''}`}
          style={{textAlign: "left"}}
        >
          { appointment.first_name + ' ' + appointment.last_name }
        </div>

        <div
          onMouseEnter={() => setHoverRow(appointment.acuity_id)}
          onMouseLeave={() => setHoverRow(null)}
          onClick={() => completeAppointment(appointment.acuity_id)}
          key={appointment.acuity_id + ' location'}
          className={`dataGridCell ${appointment.acuity_id === hoverRow ? 'dataGridCellHover' : ''}`}
          style={{textAlign: "left"}}
        >
          { appointment.location }
        </div>

        <div
          onMouseEnter={() => setHoverRow(appointment.acuity_id)}
          onMouseLeave={() => setHoverRow(null)}
          onClick={() => completeAppointment(appointment.acuity_id)}
          key={appointment.acuity_id + ' calendar'}
          className={`dataGridCell ${appointment.acuity_id === hoverRow ? 'dataGridCellHover' : ''}`}
          style={{textAlign: "left"}}
        >
          { appointment.calendar }
        </div>

        <div
          onMouseEnter={() => setHoverRow(appointment.acuity_id)}
          onMouseLeave={() => setHoverRow(null)}
          onClick={() => completeAppointment(appointment.acuity_id)}
          key={appointment.acuity_id + ' type'}
          className={`dataGridCell ${appointment.acuity_id === hoverRow ? 'dataGridCellHover' : ''}`}
          style={{textAlign: "left"}}
        >
          { appointment.appointment_type }
        </div>        
      </>
    );
  }

  // on component mount, fetch the latest report list (it'll get shown) and then subscribe to messages indicating that its been updated
  // on one of those messages, fetch again
  // on unmount unsubscribe
  useEffect(() => {
    fetchAppointmentsList();
    const handleAppointmentsListUpdateMessage = (body) => {
      fetchAppointmentsList();
    };
    subscribe('acuityListUpdate', handleAppointmentsListUpdateMessage);
    return () => {
      unsubscribe('acuityListUpdate', handleAppointmentsListUpdateMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <p>Shows next 100 appointments</p>
      <div style={{display: "grid", gridTemplateColumns: "0.6fr 0.2fr 0.5fr 1.5fr 1fr 1fr"}}>
        <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Date/time</strong></div>
        <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Price</strong></div>
        <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Name</strong></div>
        <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Location</strong></div>
        <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Calendar</strong></div>
        <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Type</strong></div>
        {
          (!appointmentsLoaded) ?
          <div className="my-5" style={{ textAlign: "center" }}>
            <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
          </div> :
          <>
            {appointments.map((entry) => getAppointmentsListItem(entry))}
          </>
        }
      </div>
    </>
  );
}

export default IncompleteAppointmentsPage;