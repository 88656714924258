import React, { useEffect, useState } from 'react';
import ShareReportButton from './ShareReportButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom';

function ReportsListItem(props) {

    const [pillClass, setPillClass] = useState("text-bg-info");
    const navigate = useNavigate();

    const processReport = () => {
        navigate('/processReport/' + props.report.premium_report_id);
    }

    useEffect(() => {
        if (props.report.status === "Ready!") {
            setPillClass("text-bg-primary");
        } else if (props.report.status === "Error") {
            setPillClass("text-bg-danger");
        }
    }, [props.report.status]);

    var age;
    var ageAlert = false;
    if (props.report.age < 60) {
        age = `${props.report.age} second${props.report.age !== 1 ? 's' : ''}`;
    } else if (props.report.age < 3600) {
        const minutes = Math.floor(props.report.age / 60);
        age = `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else {
        const hours = Math.floor(props.report.age / 3600);
        age = `${hours} hour${hours !== 1 ? 's' : ''}`;
        if (hours >= 12) {
            ageAlert = true;
        }
    }

    return (
        <li onClick={processReport} className="p-0 m-0 list-group-item list-group-item-primary list-group-item-action" style={{border: "0px"}}>
            <div className="row m-0 p-0">
                <div className="col-1 mx-0 dataCell" style={{textAlign: "left"}}>{ ageAlert ? <FontAwesomeIcon icon={solid("triangle-exclamation")} beat /> : null} { age }</div>
                <div className="col mx-0 dataCell" style={{textAlign: "left"}}>{ props.report.name }</div>
                <div className="col-2 mx-0 dataCell" stype={{textAlign: "left"}}>{ props.report.username }</div>
                <div className="col-2 mx-0 dataCell" style={{textAlign: "right"}}>
                    <span className={'badge rounded-pill ' + pillClass}>{ props.report.status }</span>
                    <ShareReportButton report={ props.report } />
                </div>
            </div>
        </li>
    );
}

export default ReportsListItem;