import React, { useState, useEffect } from 'react';
import IncompleteReportsListItem from './components/IncompleteReportsListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { subscribe, unsubscribe } from './events';

function IncompleteReportsPage(props) {

  const [reports, setReports] = useState([]);
  const [reportsLoaded, setReportsLoaded] = useState(false);

  const fetchReportList = () => {
    var fUrl = process.env.REACT_APP_API_END_POINT + 'reportslistincomplete/';
      
    fetch(fUrl,
      {
        headers: {
          'Accept': 'application/json',
        },
        method: "GET",
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data['success']) {
          setReports(data['reports_list']);
          setReportsLoaded(true);
        } else {
          setReports([]);
          setReportsLoaded(false);
        }
      }
    );
  }

  const getIncompleteReportListItem = (report) => {
    return (
      <IncompleteReportsListItem report={ report } key={ report['report_id'] } />
    );
  }

  // on component mount, fetch the latest report list (it'll get shown) and then subscribe to messages indicating that its been updated
  // on one of those messages, fetch again
  // on unmount unsubscribe
  useEffect(() => {
    fetchReportList();
    const handleReportListUpdateMessage = (body) => {
      fetchReportList();
    };
    subscribe('unprocessedReportListUpdate', handleReportListUpdateMessage);
    return () => {
      unsubscribe('unprocessedReportListUpdate', handleReportListUpdateMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-1 mx-0 px-1 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>Age</strong></div>
          <div className="col mx-0 px-1 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>Report name</strong></div>
          <div className="col-2 mx-0 px-1 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>Referring user</strong></div>
          <div className="col-2 mx-0 px-2 dataCell dataCellHeader" style={{textAlign: "right"}}><strong>Actions</strong></div>
        </div>
        {
          (!reportsLoaded) ?
          <div className="my-5" style={{ textAlign: "center" }}>
            <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
          </div> :
          <ul className="list-group m-0 p-0">
            {reports.map((report) => getIncompleteReportListItem(report))}
          </ul>
        }
      </div>
    );
}

export default IncompleteReportsPage;