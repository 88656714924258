function PointsCollector(props) {

    var pointsIncrease
    var pointsTotal
    var cssClass
    if (props.collect === 'EPC') {
        pointsIncrease = props.measure.epcIncrease
        pointsTotal = props.measure.epcTotal
        cssClass = 'epcBand'
    } else if (props.collect === 'EIR') {
        pointsIncrease = props.measure.eirIncrease
        pointsTotal = props.measure.eirTotal
        cssClass = 'eirBand'
    }

    var band;
    if (pointsTotal >= 92) {
        band = 'A'
    } else if (pointsTotal >= 81) {
        band = 'B'
    } else if (pointsTotal >= 69) {
        band = 'C'
    } else if (pointsTotal >= 55) {
        band = 'D'
    } else if (pointsTotal >= 39) {
        band = 'E'
    } else if (pointsTotal >= 21) {
        band = 'F'
    } else {
        band = 'G'
    }

    cssClass = cssClass + band

    if (props.collect === 'EPC' || props.collect === 'EIR') {
        return (
            <>
                &#43;&nbsp;<input type="number" style={{border: "none", width: "46px"}} value={pointsIncrease} onChange={props.handlePointsDeltaUpdate} />
                &nbsp;<div className={cssClass} style={{margin: "0px", textAlign: "right", padding: "0px", display: 'inline-block', height: "100%", width: "92px"}}>{ band } ( <input className={cssClass} type="number" style={{border: "none", width: "46px"}} value={pointsTotal} onChange={props.handlePointsTotalUpdate} /> )&nbsp;</div>
            </>
        )
    } else {
        return null;
    }

}

export default PointsCollector;