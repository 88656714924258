import axios from 'axios';
import { Spinner } from "react-bootstrap";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import CompletedUpload from "./components/CompletedUpload";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

/**
 * NB This component is shared between the UI for making a new report from fresh XMLs and the appointment completion screen
 */

function UploadXmlsPage(props) {

    const navigate = useNavigate();

    const [appointment, setAppointment] = useState(null);
    const [cTaxPropertyAddress, setCTaxPropertyAddress] = useState("");
    const [postcodePropertyDetails, setPostcodePropertyDetails] = useState([]);
    const [postcodeCTaxBandDetails, setPostcodeCTaxBandDetails] = useState([]);
    const [nextStepButtonDisabled, setNextStepButtonDisabled] = useState(true);
    const [nextStepButtonText, setNextStepButtonText] = useState("Select file to upload then press here");

    const [manualCouncilTaxBandActive, setManualCouncilTaxBandActive] = useState(false);
    const [manualCTaxBand, setManualCTaxBand] = useState("A");
    const [reportGenerationInitiated, setReportGenerationInitiated] = useState(false);

    const [commissioner, setCommissioner] = useState('landlord');
    const [priority, setPriority] = useState('getToC');

    // if the dropzone is disabled (becomes disabled once upload autostarts and stays that way)
    const [xmlADisabled, setXmlADisabled] = useState(false);
    const [floorPlanDisabled, setFloorPlanDisabled] = useState(false);

    // initial file upload states...
    const [xmlAFiles, setXMLAFiles] = useState([]);
    const [floorPlanFiles, setFloorPlanFiles] = useState([]);

    // initial file upload messages...
    const xmlAInitialMessage = (<strong>Drop the LIG XML file in this box (or click to select it)</strong>);
    const floorPlanInitialMessage = (<strong>Drop the floor plan graphic in this box (or click to select it)</strong>);
    
    // display content of the drop zone
    const [xmlADetail, setXmlADetail] = useState(xmlAInitialMessage);
    const [floorPlanDetail, setFloorPlanDetail] = useState(floorPlanInitialMessage);

    // filenames as they are put to the server
    const [xmlAFilename, setXmlAFilename] = useState("");
    const [floorPlanFilename, setFloorPlanFilename] = useState("");

    // remove file handlers
    const removeXmlAFiles = () => {
        setXMLAFiles([]);
    }
    const removeFloorPlanFiles = () => {
        setFloorPlanFiles([]);
    }

    // onDrop handlers
    const xmlAOnDrop = useCallback(acceptedFiles => {
        startUpload(acceptedFiles, 'A');
        setXMLAFiles([...xmlAFiles, ...acceptedFiles]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [xmlAFiles]);
    const floorPlanOnDrop = useCallback(acceptedFiles => {
        startUpload(acceptedFiles, 'FLOORPLAN');
        setFloorPlanFiles([...floorPlanFiles, ...acceptedFiles]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorPlanFiles]);

    // dropzone props
    const { getRootProps:getRootPropsXmlA, getInputProps:getInputPropsXmlA } = useDropzone({
        onDrop: xmlAOnDrop,
        disabled: xmlADisabled
    });

    const { getRootProps:getRootPropsFloorPlan, getInputProps:getInputPropsFloorPlan } = useDropzone({
        onDrop: floorPlanOnDrop,
        disabled: floorPlanDisabled
    });

    // If each individual upload is complete or not
    const [uploadAComplete, setUploadAComplete] = useState(false);
    const [uploadFloorPlanComplete, setUploadFloorPlanComplete] = useState(false);

    const uploadComplete = (axiosEvent, filename, originalFilename, which_one) => {
        if (which_one === 'A') {
            setXmlADetail(<strong>Checking file...</strong>);
            setXmlAFilename(filename);
        } else if (which_one === 'FLOORPLAN') {
            setFloorPlanDetail(<strong>Checking file...</strong>);
            setFloorPlanFilename(filename);
        }
        uploadXmlFollowup(filename, which_one, originalFilename);
      }

    const startUpload = async (files, which_one) => {    
        if (which_one === 'A') {
            setXmlADetail(startSpinner);
            setXmlADisabled(true);
            setUploadAComplete(false);
        } else if (which_one === 'FLOORPLAN') {
            setFloorPlanDetail(startSpinner);
            setFloorPlanDisabled(true);
            setUploadFloorPlanComplete(false);
        }
        await uploadFiles(files, which_one);
    }

    const resetUploads = () => {
        resetUpload('A');
        resetUpload('B');
        resetUpload('FLOORPLAN');
    }

    const resetUpload = (which, event) => {
        if (which === 'A') {
            removeXmlAFiles();
            setUploadAComplete(false);
            setXmlADisabled(false);    
            setXmlADetail(xmlAInitialMessage);
            setXmlAFilename("");
        
            // extra bits to clear the property / council tax selection
            setCTaxPropertyAddress("");
            setPostcodePropertyDetails([]);
            setPostcodeCTaxBandDetails([]);
        } else if (which === 'FLOORPLAN') {
            removeFloorPlanFiles();
            setUploadFloorPlanComplete(false);
            setFloorPlanDisabled(false);
            setFloorPlanDetail(floorPlanInitialMessage);
            setFloorPlanFilename("");
        }
    }

    const uploadFiles = (files, which_one) => {

        var extension;
        if (which_one === 'A') {
            extension = 'xml';
        } else if (which_one === 'FLOORPLAN') {
            const parts = files[0]['name'].split('.');
            extension = parts[parts.length - 1];
        }
    
        return new Promise((resolve, reject) => {
          const axiosConfig = {
            onUploadProgress: function(progressEvent) {
              var percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              var uploadMessage = percentComplete + "% complete"
              if (which_one === 'A') {
                setXmlADetail(uploadMessage);
              } else if (which_one === 'FLOORPLAN') {
                setFloorPlanDetail(uploadMessage);
              }
            }
          }
    
          const url = process.env.REACT_APP_API_END_POINT + 'uploadpremium';
          fetch(url, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                'extension': extension
              }),
              method: "POST",
              credentials: "include"
          })
          .then((resp) => resp.json())
          .then((data) => {
            if (data['success']) {
              if (which_one === 'A') {
                setXmlADetail(sendingSpinner);
              } else if (which_one === 'FLOORPLAN') {
                setFloorPlanDetail(sendingSpinner);
              }
              // got a URL to upload to, in data['upload_to']. Now run the upload...
              axios.put(data['upload_to'], files[0], axiosConfig).then(
                res => {
                  uploadComplete(res, data['filename'], files[0]['name'], which_one)
                  resolve();
                }
              );
            } else {
              if (which_one === 'A') {
                setXmlADetail(data['error']);
                setXmlADisabled(false);
              } else if (which_one === 'FLOORPLAN') {
                setFloorPlanDetail(data['error'])
                setFloorPlanDisabled(false);
              }
              // reject(data['error']);
            }
          })
        });
      }

      const uploadXmlFollowup = (filename, which_one, originalFilename) => {
        const url = process.env.REACT_APP_API_END_POINT + 'uploadxmlfollowup';
        fetch(url,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
              filename,
              which_one
            }),
            credentials: "include"
          })
          .then((resp) => resp.json())
          .then((data) => {
    
            if (data['success'] === true) {
              if (which_one === 'A') {
                setUploadAComplete(true);
                setXmlADetail(<CompletedUpload filename={originalFilename} resetUpload={() => resetUpload('A')}/>);
              } else if (which_one === 'FLOORPLAN') {
                setUploadFloorPlanComplete(true);
                setFloorPlanDetail(<CompletedUpload filename={originalFilename} resetUpload={() => resetUpload('FLOORPLAN')}/>);
              }
              if ("ctax" in data) {
                setPostcodePropertyDetails(data['ctax']['addresses']);
                setPostcodeCTaxBandDetails(data['ctax']['bands']);
              }
            } else {
              if (which_one === 'A') {
                setXmlADetail(<CompletedUpload success={false} error={data['message']} filename={originalFilename} resetUpload={() => resetUpload('A')}/>);
              } else if (which_one === 'FLOORPLAN') {
                setFloorPlanDetail(<CompletedUpload success={false} error={data['message']} filename={originalFilename} resetUpload={() => resetUpload('FLOORPLAN')}/>);
              }
            }
            
          });
      }

    const nextStep = () => {
        // xmlFilename contains the S3 key
        setReportGenerationInitiated(true);
        const url = process.env.REACT_APP_API_END_POINT + 'adminprocesspremiumupload';
        var requestBody = {
            "xml1_key": xmlAFilename,
            "floorplan_key": floorPlanFilename,
            commissioner,
            cTaxPropertyAddress,
            "mode": props.mode,
        };
        if (manualCouncilTaxBandActive) {
            requestBody['manualCTaxBand'] = manualCTaxBand
            requestBody['cTaxPropertyAddress'] = ''
        }
        if (props.mode === 'appointment') {
          requestBody["acuityId"] = props.acuityId
        }
        if (props.mode === 'new') {
          requestBody['priority'] = priority
        }
        fetch(url,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(requestBody),
            credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
            if (data['success']) {
                resetUploads();
                navigate('/processAppointment/' + data['appointmentUploadSetId'] + '/');
            } else {
                // alert(data['error']);
                // setGenerateReportButtonDisabled(true);
            }
        });
    }

    useEffect(() => {
      if (props.mode === 'appointment') {
        var fUrl = process.env.REACT_APP_API_END_POINT + 'acuityfetch/';
        fetch(fUrl, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                "acuityId": props.acuityId
            })
        })
        .then((resp) => resp.json())
        .then((data) => {
            if (data['success']) {
                setAppointment(data['appointment']);
            }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startSpinner = (
        <div className="my-5" style={{ textAlign: "center" }}>
            <FontAwesomeIcon className="spinner-slow" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
        </div>
    );

    const sendingSpinner = (
        <div className="my-5" style={{ textAlign: "center" }}>
            <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
        </div>
    );

    useEffect(() => {
      if (reportGenerationInitiated) {
        setNextStepButtonDisabled(true);
        setNextStepButtonText("Wait...");
      } else {
        if (uploadAComplete && ((props.mode !== 'appointment') || uploadFloorPlanComplete) && (cTaxPropertyAddress !== "" || manualCouncilTaxBandActive)) {
          setNextStepButtonDisabled(false);
          setNextStepButtonText("Next step");
        } else {
          setNextStepButtonDisabled(true);
          setNextStepButtonText("Complete all steps then press here");
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadAComplete, reportGenerationInitiated, uploadFloorPlanComplete, cTaxPropertyAddress, manualCouncilTaxBandActive]);

    if (props.mode === 'appointment' && appointment === null) {
        return (
            <Spinner/>
        )
    } else {
        return (
            <div>
                { props.mode === 'appointment' && <p>
                    Please verify you are uploading the XMLs for the following appointment:<br/>
                    <strong>Location</strong>: {appointment.location}<br/>
                    At: {appointment.date_time}<br/>
                </p> }
                <div className="pb-2">
                    <div {...getRootPropsXmlA({className: "dropzone"})}>
                        <input {...getInputPropsXmlA()} />
                        { xmlADetail }
                    </div>
                </div>
                { props.mode === 'appointment' && <div className="pb-2">
                    <div {...getRootPropsFloorPlan({className: "dropzone"})}>
                        <input {...getInputPropsFloorPlan()} />
                        { floorPlanDetail }
                    </div>
                </div> }

                <div className="pb-2">
                  <div>Was this report commissioned for a landlord or a homeowner?</div>
                </div>

                <div className="pb-3">
                  <select name="commissioner" style={{width: "250px"}} className="form-select" value={commissioner} onChange={e => setCommissioner(e.target.value)}>
                    <option value="landlord">A landlord</option>
                    <option value="homeowner">A homeowner</option>
                    <option value="other">Don&apos;t know / other</option>
                  </select>
                </div>

                { props.mode === 'new' && <>
                  <div className="pb-3">
                    <div>What is the priority plan?</div>
                  </div>
                  <div className="pb-3">
                    <select name="priority" className="form-select" style={{width: "600px"}} value={priority} onChange={e => setPriority(e.target.value)}>
                      <option value="getToC">I want to get my property to an EPC rating of C for the lowest cost possible</option>
                      <option value="improveEnergyEfficiency">I want to improve the energy efficiency of my property</option>
                      <option value="improveEIR">I want to improve the EIR score of my property</option>
                      <option value="improveComfort">I want to improve the comfort of my property</option>
                    </select>
                  </div>
                </> }

                <div className="pb-3">
                  <div>After uploading the files, please select the correct property from the list below to identify its council tax band</div>  
                </div>

                <div className="pb-3">
                    <select name="ctax" style={{width: "500px"}} className="form-select" value={cTaxPropertyAddress} onChange={e => setCTaxPropertyAddress((e.target.value))}>
                        <option key="empty" value="" disabled hidden>Please choose...</option> 
                    {
                        postcodePropertyDetails.map((entry) => { return (
                        <option key={entry.address} value={entry.address}>
                            {entry.address} - (Band {entry.band} ~&pound;{(parseFloat(postcodeCTaxBandDetails['band_' + entry.band.toLowerCase()].replace(',', '')) / 12).toFixed(2)} / month)
                        </option>
                        )})
                    }  
                    </select>
                </div>

                <div className="pb-3">
                    <div>If the property is not listed in the list above, or the given council tax band is wrong, please select it manually...</div>  
                </div>


                <div className="pb-3 form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="overrideCtax" checked={manualCouncilTaxBandActive} onChange={e => setManualCouncilTaxBandActive(e.target.checked)} />
                    <label className="form-check-label" htmlFor="overrideCtax"><strong>Manually set council tax band</strong></label>
                </div>

                { manualCouncilTaxBandActive ? 
                    <div className="pb-3">
                        <select name="manualCtax" style={{width: "250px"}} className="form-select" value={manualCTaxBand} onChange={e => setManualCTaxBand(e.target.value)}>
                            <option key="A" value="A">A</option> 
                            <option key="B" value="B">B</option> 
                            <option key="C" value="C">C</option> 
                            <option key="D" value="D">D</option> 
                            <option key="E" value="E">E</option> 
                            <option key="F" value="F">F</option> 
                            <option key="G" value="G">G</option> 
                            <option key="H" value="H">H</option> 
                        </select>
                    </div>
                : null }
                <div>
                    <button type="submit" onClick={nextStep} className="btn btn-primary" disabled={nextStepButtonDisabled}>{nextStepButtonText}</button>
                </div>
            </div>
        )
    }
}

export default UploadXmlsPage;