import React, { useState } from 'react';
import ReorderableMeasuresList from './ReorderableMeasuresList';

function ReorderablePlan(props) {
    
    const [measures, setMeasuresState] = useState({});
    const [measuresOrder, setMeasuresOrderState] = useState([]);

    const setMeasures = (_measures) => {
        setMeasuresState(_measures);
        props.updateMasterData(_measures, 'measures');
    }

    const setMeasuresOrder = (_measuresOrder) => {
        setMeasuresOrderState(_measuresOrder);
        props.updateMasterData(_measuresOrder, 'measuresOrder');
    }

    var extraText = '';
    if (props.plan.collect === 'EIR') {
        extraText = ', starts at ' + props.eirStart;
    } else if (props.plan.collect === 'EPC') {
        extraText = ', starts at ' + props.epcStart;
    }

    return (
        <div className="reorderablePlanContainer">
            <div className="pageSubSection">{props.plan.name}</div>
            <div>Update: {props.plan.collect}{extraText}</div>
            <ReorderableMeasuresList {...{measures, setMeasures, measuresOrder, setMeasuresOrder}} epcStart={props.epcStart} eirStart={props.eirStart} plan={props.plan} />
        </div>
    );
}

export default ReorderablePlan;